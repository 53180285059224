import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { JobReducerState } from './types'

export const initialState: JobReducerState = {
  page: 'job-detail',
  jobDetail: undefined,
  jobDetailPage: {
    selectedApplicant: undefined,
    applicationIds: [],
    nextApplicantStatus: undefined,
    chatIcon: '/assets/icons/ic_chat.svg',
    whatsappIcon: '/assets/icons/ic_whatsapp_green.svg',
    webProfileCVIcon: '/assets/icons/ic_web_profile_cv.svg',
    isPlayerOpen: false,
    isRecommendedInfoShown: true,
    selectedApplicantFilter: null,
    applicantFilterIndex: null,
    applicantFile: null,
    selectedTabIndex: 0,
  },
  selectionPage: {
    numberOfCandidates: 6,
  },
  jobListingQueries: {
    tab: 'all',
    order: 'Latest',
  },
  isQuestionModalOpen: false,
  question: '',
  modalId: '',
  newPostHighlight: '',
  selectedCandidate: [],
  jobDetailDrawer: {
    jobId: '',
    open: false,
    selectedTab: 'detail',
  },
  jobCommentDrawer: {
    jobId: '',
    open: false,
  },
  editCommentData: {
    commentId: '',
    editCommentText: '',
  },
  reportCommentModal: {
    open: false,
    commentId: '',
  },
}

const jobSlice = createSlice({
  name: 'job-detail',
  initialState,
  reducers: {
    setOrder(
      state,
      action: PayloadAction<JobReducerState['jobListingQueries']['order']>
    ) {
      state.jobListingQueries.order = action.payload
    },
    setJobsStatusTab(
      state,
      action: PayloadAction<JobReducerState['jobListingQueries']['tab']>
    ) {
      state.jobListingQueries = {
        ...state.jobListingQueries,
        searchText: '',
        tab: action.payload,
      }
    },
    setPage: (state, action: PayloadAction<JobReducerState['page']>) => {
      state.page = action.payload
    },
    setJobListingQueries(
      state,
      action: PayloadAction<Partial<JobReducerState['jobListingQueries']>>
    ) {
      state.jobListingQueries = {
        ...state.jobListingQueries,
        ...action.payload,
      }
    },
    setNumberOfCandidates: (state, action: PayloadAction<number>) => {
      state.selectionPage.numberOfCandidates = action.payload
    },
    decreaseNumberOfCandidates: (state) => {
      state.selectionPage.numberOfCandidates -= 1
    },
    setJobDetail: (
      state,
      action: PayloadAction<JobReducerState['jobDetail']>
    ) => {
      state.jobDetail = action.payload
    },
    setIsQuestionModal: (
      state,
      action: PayloadAction<JobReducerState['isQuestionModalOpen']>
    ) => {
      state.isQuestionModalOpen = action.payload
    },
    setQuestion: (
      state,
      action: PayloadAction<JobReducerState['question']>
    ) => {
      state.question = action.payload
    },
    setModalId: (state, action: PayloadAction<JobReducerState['modalId']>) => {
      state.modalId = action.payload
    },
    changeNewPostHighlight: (
      state,
      action: PayloadAction<JobReducerState['newPostHighlight']>
    ) => {
      state.newPostHighlight = action.payload
    },
    changeSelectedCandidate: (
      state,
      action: PayloadAction<JobReducerState['selectedCandidate']>
    ) => {
      state.selectedCandidate = action.payload
    },
    setSelectedApplicant: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['selectedApplicant']
      >
    ) => {
      state.jobDetailPage.selectedApplicant = action.payload
    },
    setApplicationIds: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['applicationIds']>
    ) => {
      state.jobDetailPage.applicationIds = action.payload
    },
    setNextApplicantStatus: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['nextApplicantStatus']
      >
    ) => {
      state.jobDetailPage.nextApplicantStatus = action.payload
    },
    setChatIcon: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['chatIcon']>
    ) => {
      state.jobDetailPage.chatIcon = action.payload
    },
    setWhatsappIcon: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['whatsappIcon']>
    ) => {
      state.jobDetailPage.whatsappIcon = action.payload
    },
    setWebProfileCVIcon: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['webProfileCVIcon']
      >
    ) => {
      state.jobDetailPage.webProfileCVIcon = action.payload
    },
    setIsPlayerOpen: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['isPlayerOpen']>
    ) => {
      state.jobDetailPage.isPlayerOpen = action.payload
    },
    setIsRecommendedInfoShown: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['isRecommendedInfoShown']
      >
    ) => {
      state.jobDetailPage.isRecommendedInfoShown = action.payload
    },
    setSelectedApplicantFilter: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['selectedApplicantFilter']
      >
    ) => {
      state.jobDetailPage.selectedApplicantFilter = action.payload
    },
    setApplicantFilterIndex: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['applicantFilterIndex']
      >
    ) => {
      state.jobDetailPage.applicantFilterIndex = action.payload
    },
    setApplicantFileURL: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['applicantFile']>
    ) => {
      state.jobDetailPage.applicantFile = action.payload
    },
    setSelectedTabIndex: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['selectedTabIndex']
      >
    ) => {
      state.jobDetailPage.selectedTabIndex = action.payload
    },
    setJobDetailDrawerState(
      state,
      action: PayloadAction<Partial<JobReducerState['jobDetailDrawer']>>
    ) {
      state.jobDetailDrawer = {
        ...state.jobDetailDrawer,
        ...action.payload,
      }
    },
    resetJobDetailDrawerState(state) {
      state.jobDetailDrawer = { ...initialState.jobDetailDrawer }
    },
    setJobCommentDrawerState(
      state,
      action: PayloadAction<Partial<JobReducerState['jobCommentDrawer']>>
    ) {
      state.jobCommentDrawer = {
        ...state.jobCommentDrawer,
        ...action.payload,
      }
    },
    resetJobCommentDrawerState(state) {
      state.jobCommentDrawer = { ...initialState.jobCommentDrawer }
    },
    setEditCommentDataState(
      state,
      action: PayloadAction<Partial<JobReducerState['editCommentData']>>
    ) {
      state.editCommentData = {
        ...state.editCommentData,
        ...action.payload,
      }
    },
    resetEditCommentDataState(state) {
      state.editCommentData = { ...initialState.editCommentData }
    },
    setReportCommentModalState(
      state,
      action: PayloadAction<Partial<JobReducerState['reportCommentModal']>>
    ) {
      state.reportCommentModal = {
        ...state.reportCommentModal,
        ...action.payload,
      }
    },
    resetReportCommentModalState(state) {
      state.reportCommentModal = { ...initialState.reportCommentModal }
    },
  },
})

export const useJobsActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(jobSlice.actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export const jobActions = jobSlice.actions
export default jobSlice.reducer
